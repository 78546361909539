
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      selectedItem: null,
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        {
          title: 'Consultas y denuncias',
          src: '/svg/ConsultasNegro.svg',
          to: '/',
          acceptedRoles: ['admin', 'operator', 'technician'],
        },
        {
          title: 'Reporte técnico',
          icon: 'mdi-file-alert-outline',
          to: '/technical-support',
          acceptedRoles: ['admin', 'operator', 'technician'],
        },
        {
          title: 'Operadores',
          src: '/svg/Operadores.svg',
          to: '/admin/operators',
          acceptedRoles: ['admin'],
        },
        {
          title: 'Buzón de entrada',
          src: '/svg/Buzon.svg',
          to: '/inbox',
          acceptedRoles: ['admin', 'operator'],
        },
        {
          title: 'Buzón de reportes',
          icon: 'mdi-inbox-multiple-outline',
          to: '/inbox-technician',
          acceptedRoles: ['admin', 'technician'],
        },
        {
          title: 'Preguntas frecuentas',
          icon: 'mdi-frequently-asked-questions',
          to: '/faq',
          acceptedRoles: ['admin', 'operator', 'technician'],
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js',
      menuDrown: [
        {
          title: 'Mi Perfil',
          to: '/profile',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/userInfo',
      isAuthenticated: 'user/isAuthenticated',
      role: 'user/getRole',
    }),
    displayName() {
      let name = this.$store.getters['user/displayName']
      if (name?.length > 0 && name.length > 15) {
        name = name.slice(0, 13) + '...'
      } else if (name?.length <= 15 && name.length > 0) {
        name = this.$store.getters['user/displayName']
      } else if ((name === null || name === undefined) && this.user.name) {
        name =
          this.user.name.split(' ')[0] + ' ' + this.user.lastName.split(' ')[0]
        name = name.slice(0, 13) + '...'
      } else {
        name = ''
      }

      return name
    },
    roleText() {
      let role = 'Operador'
      if (this.role === 'admin') {
        role = 'Administrador'
      }
      if (this.role === 'technician') {
        role = 'Técnico'
      }
      return role
    },
  },
  methods: {
    goToMainRN() {
      window.location.href = 'https://www.loteriaderionegro.gob.ar/'
      // window.open(
      //     'https://www.loteriaderionegro.gob.ar/',
      //     '_blank'
      //   )
    },
    checkIfHasRole(item) {
      let hasRole = false
      const acceptedRoles = item.acceptedRoles
      const isInAcceptedRoles = acceptedRoles.find((role) => role === this.role)
      if (isInAcceptedRoles) hasRole = true
      return hasRole
    },
  },
}
