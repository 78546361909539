const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['onlyAdmin'] = require('../middleware/onlyAdmin.js')
middleware['onlyAdmin'] = middleware['onlyAdmin'].default || middleware['onlyAdmin']

middleware['onlyOperator&Admin'] = require('../middleware/onlyOperator&Admin.js')
middleware['onlyOperator&Admin'] = middleware['onlyOperator&Admin'].default || middleware['onlyOperator&Admin']

middleware['onlyTech&Admin'] = require('../middleware/onlyTech&Admin.js')
middleware['onlyTech&Admin'] = middleware['onlyTech&Admin'].default || middleware['onlyTech&Admin']

middleware['unauthenticated'] = require('../middleware/unauthenticated.js')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

export default middleware
